import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/a/(admin)": [28,[5]],
		"/a/(admin)/accounts": [29,[5]],
		"/a/(admin)/invites": [30,[5]],
		"/a/login": [32],
		"/a/(admin)/waitlist": [31,[5]],
		"/(app)/collection": [7,[2]],
		"/(app)/collection/[id]": [8,[2]],
		"/(app)/login": [9,[2]],
		"/(app)/media": [10,[2]],
		"/(app)/reactivate": [11,[2]],
		"/(registration)/recovery": [22,[3]],
		"/(registration)/register": [23,[3]],
		"/(registration)/register/(no_login_link)/payment": [24,[4]],
		"/(registration)/register/(no_login_link)/recovery": [25,[4]],
		"/(registration)/register/user": [26,[3]],
		"/(registration)/register/waitlist": [27,[3]],
		"/(app)/settings": [12,[2]],
		"/(app)/settings/email": [13,[2]],
		"/(app)/settings/password": [14,[2]],
		"/(app)/settings/payment/update": [15,[2]],
		"/(app)/settings/payment/update/success": [16,[2]],
		"/(app)/shared-collection": [17,[2]],
		"/(app)/subscription": [18,[2]],
		"/(app)/subscription/checkout": [19,[2]],
		"/(app)/subscription/checkout/success": [20,[2]],
		"/s/[id]": [33],
		"/(app)/trash": [21,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';